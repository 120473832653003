<template>
  <div class="resource">
    <div class="breadcrumb w-1400">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/resource' }"
          >资源集市</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/enterpriseResource' }"
          >企业资源</el-breadcrumb-item
        >
        <el-breadcrumb-item>企业列表</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="params-box w-1400">
      <div class="clearfix">
        <label class="fl">企业类型</label>
        <i class="fl" style="margin-right: 20px">:</i>
        <div class=" fl"  :class="{isactive:!params.filter.enterpriseType}"  @click="params.filter.enterpriseType='';getenterpriseList()">全部</div>
        <ul class="clearfix fl search-list">
          <li v-for="item in classify" class="fl classify-item" :class="{isactive:params.filter.enterpriseType == item}" :key="item"   @click="params.filter.enterpriseType=item;getenterpriseList()">
            {{ item }}
          </li>
        </ul>
      </div>
      <!-- <div class="clearfix">
        <label class="fl">企业性质</label>
        <i class="fl" style="margin-right: 20px">:</i>
        <div class="isactive fl">不限</div>
        <ul class="clearfix fl search-list">
          <li v-for="item in nature" class="fl classify-item" :key="item">
            {{ item }}
          </li>
        </ul>
      </div> -->
      <div class="clearfix">
        <label class="fl">地区</label>
        <i class="fl" style="margin-right: 20px">:</i>
        <div  class="fl"  :class="{isactive:!params.filter.regionCode}" @click="params.filter.regionCode='';getenterpriseList()">不限</div>
        <ul class="clearfix fl search-list">
          <li v-for="item in region" :class="{isactive:params.filter.regionCode == item.code}" class="fl classify-item" :key="item.code" @click="params.filter.regionCode=item.code;getenterpriseList()">
            {{ item.name }}
          </li>
        </ul>
      </div>
    </div>
    <div class="w-1400">
      <el-row :gutter="40">
        <el-col :span="18">
          <div class="res-main">
            <div
              class="join-item clearfix"
              v-for="item in pageData.list"
              :key="item.id + 'jo'"
            >
              <router-link :to="`/enterprisedetail?id=${item.id}`">
                <div class="fl join-pic">
                  <el-image
                    style="width: 100%; height: 100%"
                    :src="item.photoUrls[0]"
                  ></el-image>
                </div>
                <div class="join-info fr">
                  <h4 class="ellipsis">{{ item.name }}</h4>
                  <div class="join-address">
                    <i class="el-icon-location"></i>
                    {{ item.address }}
                  </div>
                  <div class="join-intro">
                    {{ item.introduction }}
                  </div>
                </div>
              </router-link>
            </div>
            <el-pagination
              class="pagination"
              background
              layout="prev, pager, next,jumper"
              :total="pageData.total"
              @current-change="handleCurrentChange"
            >
            </el-pagination>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="tj">
            <h4><i class="arrow-r"></i> 资源推荐</h4>
            <div
              class="tj-box clearfix"
              v-for="item in tuijianpageData"
              :key="item.id + 'tj'"
            >
              <router-link :to="`/enterprisedetail?id=${item.id}`">
                <div class="tj-pic fl">
                  <el-image
                    style="width: 100%; height: 100%"
                    :src="item.photoUrls[0]"
                  ></el-image>
                </div>
                <div class="tj-info fr">
                  <h4 class="ellipsis">{{ item.name }}</h4>
                  <div class="join-address">
                    <i class="el-icon-location"></i>
                    {{ item.address }}
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import { GetEnterprises } from "@/api/ziyuanjishi";
export default {
  data() {
    return {
      classify: ["一般企业", "省级合作社", "龙头企业"],
      region: [
        { name: "全部", code: 0 },
        { name: "大通县", code: 630121 },
        { name: "湟源县", code: 630123 },
        { name: "湟中区", code: 630122 },
        { name: "城东区", code: 630102 },
        { name: "城中区", code: 630103 },
        { name: "城西区", code: 630104 },
        { name: "城北区", code: 630105 },
      ],

      pageData: {},
      tuijianpageData: {},
      params: {
        currentPage: 1,
        pageSize: 10,
        filter: {
          enterpriseType: "",
          regionCode: "",
        },
      },
    };
  },
  mounted() {
    this.getenterpriseList();
    this.getuijianenterpriseList();
  },
  methods: {
    getenterpriseList() {
      GetEnterprises(this.params).then((res) => {
        const data = res.data.data;
        data.list.forEach((d) => {
          d.photoUrls = d.photoUrls.split(",");
        });
        this.pageData = data;
      });
    },
    getuijianenterpriseList() {
      const tuijianparams = {
        currentPage: 1,
        pageSize: 10,
        filter: { enterpriseType: "龙头企业" },
      };
      GetEnterprises(tuijianparams).then((res) => {
        const data = res.data.data.list;
        data.forEach((d) => {
          d.photoUrls = d.photoUrls.split(",");
        });
        this.tuijianpageData = data;
      });
    },
    //分页
    handleCurrentChange(page) {
      this.params.currentPage = page;
      this.getenterpriseList();
    },
  },
};
</script>
<style scoped>
.tj-info {
  width: calc(100% - 110px);
}
.tj-box {
  margin-top: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #777;
}
.tj-pic {
  width: 100px;
  height: 100px;
  background: #5c8ad7;
  padding: 15px;
}
.tj .arrow-r {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url(../../../assets/img/right2.png) no-repeat;
  background-size: 100% 100%;
  transform: translateY(2px);
}
.join-intro {
  font-size: 16px;
  line-height: 24px;
  height: 72px;
  color: #666;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.join-address i {
  color: #0e69c2;
}
.join-address {
  font-size: 16px;
  line-height: 22px;
  height: 28px;
  color: #666;
}
.join-info h4 {
  line-height: 50px;
  font-size: 24px;
}
.join-info {
  width: calc(100% - 170px);
  height: 150px;
  overflow: hidden;
}
.join-pic {
  width: 150px;
  height: 150px;
  background: #3aced8;
  padding: 15px;
}
.join-item {
  border: 1px solid #777;
  padding: 20px;
  /* margin-top: 30px; */
}
.tj-title {
  line-height: 32px;
  font-size: 16px;
}
.tj-pic {
  height: 100px;
}
.tj h4 {
  font-size: 20px;
  line-height: 42px;
}
.tj {
  padding: 0 5px;
  border: 1px solid #777;
}
.res-price span:first-child {
  font-size: 20px;
  color: #fe620d;
}
.res-price {
  display: flex;
  justify-content: space-between;
  padding-right: 30px;
  font-size: 16px;
  margin-top: 30px;
}
.res-mark span:nth-child(1) {
  background: #0e69c2;
}
.res-mark span:nth-child(2) {
  background: #fe620d;
}
.res-mark span:nth-child(3) {
  background: #3aced8;
}
.res-mark span {
  padding: 10px 20px;
  color: #fff;
  margin-right: 16px;
  font-size: 16px;
  display: inline-block;
}
.res-mark {
  margin-top: 30px;
  /* line-height: 20px; */
}
.res-info h5 {
  font-size: 20px;
  height: 40px;
  line-height: 40px;
  font-weight: 500;
}
.res-info h4 {
  font-size: 22px;
  height: 48px;
  line-height: 48px;
}
.res-info {
  width: calc(100% - 420px);
}
.res-pic {
  width: 390px;
  height: 250px;
}
.res-item {
  padding: 20px 0;
  border-top: 1px solid #777;
}
.classify-item {
  padding: 0px 20px;
  font-size: 18px;
  cursor: pointer;
  color: #333;
}
.search-list {
  /* width: 700px; */
}
.params-box span.isactive,
.v-list-sort span.isactive,
.isactive {
  color: #4283d2;
}
.params-box {
  margin-top: 20px;
  border-bottom: 1px solid #777;
  /* font-family: '宋体'; */
}
.params-box div {
  font-size: 18px;
  font-weight: 500;
  /* height: 40px; */
  line-height: 40px;
}
.params-box label {
  width: 100px;
  text-align: justify;
  text-align-last: justify;
  font-weight: 550;
}
.resource {
  font-family: "微软雅黑";
  /* background: url(../../../assets/img/qylist-bg.png) no-repeat; */
  background-size: auto 300px;
}
.breadcrumb >>> .el-breadcrumb .is-link,
.breadcrumb >>> .el-breadcrumb .el-breadcrumb__separator,
.breadcrumb >>> .el-breadcrumb .el-breadcrumb__inner {
  font-size: 18px;
  font-family: "微软雅黑";
  color: #0e6ac1;
}
.breadcrumb {
  padding: 20px 0 10px 0;
  border-bottom: 1px solid #0e6ac1;
  margin-bottom: 50px;
}
</style>
